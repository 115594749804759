var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":16}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Information du collecteur")])]},proxy:true}])},[_c('div',{staticClass:"text-right mb-4"},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.changeState()}}},[_vm._v(" Modifier le collecteur ")]),_c('router-link',{staticClass:"mx-2",attrs:{"to":{
              name: 'Collecteur_depot',
              params: { id: this.$route.params.id },
            }}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("Deversement collecteur")])],1),_c('router-link',{staticClass:"mx-2",attrs:{"to":{
              name: 'Collecteur_client',
              params: { id: this.$route.params.id },
            }}},[_c('a-button',{attrs:{"type":"primary"}},[_vm._v("Liste des clients")])],1),_c('a-button',{staticClass:"mx-2",on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("Retour")])],1),_c('a-row',{attrs:{"gutter":[24, 24]}},[_c('a-col',{attrs:{"span":24}},[_c('a-card',{staticClass:"card-billing-info",attrs:{"bordered":false}},[_c('div',{staticClass:"col-info"},[_c('a-descriptions',{attrs:{"title":'date de création: ' +
                    new Date(_vm.collecteur.created_at).toLocaleString(),"column":2}},[_c('a-descriptions-item',{attrs:{"label":"Nom"}},[_vm._v(" "+_vm._s(_vm.collecteur.nom)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Prenom"}},[_vm._v(" "+_vm._s(_vm.collecteur.prenom)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Numéro de téléphone"}},[_vm._v(" (+228) "+_vm._s(_vm.collecteur.numero)+" ")]),_c('a-descriptions-item'),_c('a-descriptions-item',{attrs:{"label":"Ville"}},[_vm._v(" "+_vm._s(_vm.ville)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Quartier"}},[_vm._v(" "+_vm._s(_vm.quartier)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Code secret"}},[_vm._v(" "+_vm._s(_vm.collecteur.code_secret)+" ")]),_c('a-descriptions-item',{attrs:{"label":"Nom d'agence"}},[_vm._v(" "+_vm._s(_vm.collecteur.agc_name)+" ")])],1)],1)])],1)],1),(_vm.state == true)?_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Modification du collecteur")])]},proxy:true}],null,false,4089593624)},[_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form_update,"hideRequiredMark":true},on:{"submit":_vm.collecteurUpdate}},[_c('a-row',{attrs:{"type":"flex","gutter":24}},[_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Nom du collecteur","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'nom',
                        {
                          initialValue: _vm.collecteur.nom,
                          rules: [
                            {
                              required: true,
                              message: 'Nom du collecteur est vide!',
                            } ],
                        } ]),expression:"[\n                        'nom',\n                        {\n                          initialValue: collecteur.nom,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Nom du collecteur est vide!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"text","placeholder":"Nom agent collecteur"}})],1)],1),_c('a-col',{attrs:{"span":12,"md":12}},[_c('a-form-item',{attrs:{"label":"Prénom du collecteur","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                        'prenom',
                        {
                          initialValue: _vm.collecteur.prenom,
                          rules: [
                            {
                              required: true,
                              message: 'Prénom du collecteur est vide!',
                            } ],
                        } ]),expression:"[\n                        'prenom',\n                        {\n                          initialValue: collecteur.prenom,\n                          rules: [\n                            {\n                              required: true,\n                              message: 'Prénom du collecteur est vide!',\n                            },\n                          ],\n                        },\n                      ]"}],attrs:{"type":"text","placeholder":"Prénom agent collecteur"}})],1)],1),_c('a-col',{attrs:{"span":24,"md":24}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('a-button',{attrs:{"htmlType":"submit","type":"primary"}},[_vm._v(" Modifier ")])],1)])],1)],1)],1)],1):_vm._e(),_c('a-col',{staticClass:"mb-24",attrs:{"span":24,"md":24}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: 0, paddingBottom: '16px' }},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Statistique du collecteur")])]},proxy:true}])},[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":12,"lg":12,"xl":12}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1)],1)],1)],1)],1),_c('a-col',{staticClass:"mb-24",attrs:{"span":8,"md":8}},[_c('a-card',{staticClass:"header-solid h-full",attrs:{"bordered":false,"bodyStyle":{ paddingTop: '16px', paddingBottom: '16px' }}},[[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Generer code secret")])],_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form_code,"hideRequiredMark":true},on:{"submit":_vm.changeCode}},[_c('a-form-item',{attrs:{"label":"Code secret generer","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code_secret',
                {
                  initialValue: _vm.code_secret,
                  rules: [
                    {
                      required: true,
                      message: 'Code secret generer incorrect!',
                    } ],
                } ]),expression:"[\n                'code_secret',\n                {\n                  initialValue: code_secret,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Code secret generer incorrect!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":"","type":"text","placeholder":"Code secret"}})],1),_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Generer ")])],1)],1),[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Generer mot de passe")])],_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form_password,"hideRequiredMark":true},on:{"submit":_vm.passwordSubmit}},[_c('a-form-item',{attrs:{"label":"Mot de passe generer","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'password',
                {
                  initialValue: _vm.password,
                  rules: [
                    {
                      required: true,
                      message: 'Mot de passe generer incorrect!',
                    } ],
                } ]),expression:"[\n                'password',\n                {\n                  initialValue: password,\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Mot de passe generer incorrect!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"disabled":"","type":"text","placeholder":"Mot de passe"}})],1),_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Generer ")])],1)],1),[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Deconnectez le collecteur")])],_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form_disconnect,"hideRequiredMark":true},on:{"submit":_vm.disconnectSubmit}},[_c('a-form-item',{attrs:{"label":"Code secret","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code_secret',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Code secret est vide!',
                    } ],
                } ]),expression:"[\n                'code_secret',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Code secret est vide!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"text","placeholder":"Code secret"}})],1),_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"danger","html-type":"submit"}},[_vm._v(" Deconnexion ")])],1)],1),[_c('h6',{staticClass:"font-semibold m-0"},[_vm._v("Statistique par date")])],_c('a-form',{staticClass:"login-form",attrs:{"id":"components-form-demo-normal-login","form":_vm.form_date,"hideRequiredMark":true},on:{"submit":_vm.dateStateSubmit}},[_c('a-form-item',{attrs:{"label":"Date de debut"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'date_debut',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Date debut incorrect!',
                    } ],
                } ]),expression:"[\n                'date_debut',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Date debut incorrect!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"date","placeholder":"Date debut"}})],1),_c('a-form-item',{attrs:{"label":"Date de fin :","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'date_fin',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Date de fin incorrect!',
                    } ],
                } ]),expression:"[\n                'date_fin',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Date de fin incorrect!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"date","placeholder":"Date de fin"}})],1),_c('a-form-item',{attrs:{"label":"Code secret","colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                'code_secret',
                {
                  rules: [
                    {
                      required: true,
                      message: 'Code secret est vide!',
                    } ],
                } ]),expression:"[\n                'code_secret',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Code secret est vide!',\n                    },\n                  ],\n                },\n              ]"}],attrs:{"type":"text","placeholder":"Code secret"}})],1),_c('div',{staticClass:"mb-4 text-right"},[_c('a-button',{staticClass:"login-form-button",attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Lancer la recherche ")])],1)],1)],2)],1)],1),_c('a-modal',{attrs:{"title":"Statistique d'une periode daté","visible":_vm.visible},on:{"cancel":_vm.handleCancel}},[_c('a-row',{attrs:{"gutter":24}},_vm._l((_vm.stats_date),function(stat,index){return _c('a-col',{key:index,staticClass:"mb-24",attrs:{"span":24,"lg":24,"xl":24}},[_c('WidgetCounter',{attrs:{"title":stat.title,"value":stat.value,"prefix":stat.prefix,"suffix":stat.suffix,"icon":stat.icon,"status":stat.status}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }